<template>
  <base-layout>

<ion-searchbar
    show-cancel-button="focus"
    @ionChange="filter(search)"
    :value="search"
    @ionInput="search = $event.target.value;"
></ion-searchbar>
 <ion-content>

  <div id="presentation-list" >
      <template v-if="this.filtered.length==0">
    <div v-for="presentation in presentations" :key="presentation.id">
    <template  v-if="presentation.abstract_id">
      <router-link :to="'/app/presentations/' + presentation.id">
        <ion-item detail class="presentation-list-item-modern">
          <ion-row style="width:100%">
            <ion-col size="auto" style="text-align: center">

            </ion-col>
            <ion-col size="10" class="content">
              <div>              
                <span style="padding: 5px; padding-left: 10px;  font-size: 13px" class="w600 title" ><span
                    v-if="presentation.code">{{ presentation.code }} - </span><span
                    v-html="presentation.title"></span></span>
                <span class="speaker-line" v-for="speaker in presentation.speakers"
                      :key="speaker.id">{{ speaker.display_name }}, <span
                    v-if="speaker.city">{{ speaker.city }}</span> <span
                    v-if="speaker.country">({{ speaker.country }})</span>
                </span>
                <p v-show="presentation.sess_start">{{
                    moment(presentation.sess_start).format('dddd, HH:mm')
                  }}{{ moment(presentation.sess_end).format('- HH:mm') }}
                  <span translate="UHR"></span></p>
                <small v-if="presentation.note" style="font-style:italic; color: dimgrey; white-space: normal">
                  “{{ presentation.note }}“
                </small>
              </div>
            </ion-col>
          </ion-row>
        </ion-item>
      </router-link>
      </template>
    </div>
      </template>

    <template v-if="this.filtered.length>0">
    <div v-for="presentation in filtered" :key="presentation.id">
    <template  v-if="presentation.abstract_id">
      <router-link :to="'/app/presentations/' + presentation.id">
        <ion-item detail class="presentation-list-item-modern">
          <ion-row style="width:100%">
            <ion-col size="auto" style="text-align: center">

            </ion-col>
            <ion-col size="10" class="content">
             
              <div>              
                <span style="padding: 5px; padding-left: 10px;  font-size: 13px" class="w600 title" ><span
                    v-if="presentation.code">{{ presentation.code }} - </span><span
                    v-html="presentation.title"></span></span>
                <span class="speaker-line" v-for="speaker in presentation.speakers"
                      :key="speaker.id">{{ speaker.display_name }}, <span
                    v-if="speaker.city">{{ speaker.city }}</span> <span
                    v-if="speaker.country">({{ speaker.country }})</span>
                </span>
                <p v-show="presentation.sess_start">{{
                    moment(presentation.sess_start).format('dddd, HH:mm')
                  }}{{ moment(presentation.sess_end).format('- HH:mm') }}
                  <span translate="UHR"></span></p>
                <small v-if="presentation.note" style="font-style:italic; color: dimgrey; white-space: normal">
                  “{{ presentation.note }}“
                </small>
              </div>
            </ion-col>
          </ion-row>
        </ion-item>
      </router-link>
      </template>
    </div>
    </template>
  </div>
 </ion-content>
  </base-layout>
</template>
<script>
import {
  IonItem,
  IonRow,
  IonCol,
  IonSearchbar
} from "@ionic/vue";
import moment from 'moment';
import {mapActions} from "vuex";

export default {
  data() {
    return {
      presentations: [],
      days: {},
      activeDay: '',
      search: "",
      filtered: []
    }
  },
  components: {
    IonItem,
    IonRow,
    IonCol,
    IonSearchbar
  },
  created() {
    this.moment = moment;
    console.log('presentations')
  },

  methods:{
    ...mapActions("presentations", ["getAllPosters"]),

    filter(term) {
      console.log(term)
      if (term.length > 0) {
        this.filtered = this.presentations.filter(presentation =>
            Object.keys(presentation).some(k =>
                presentation[k]
                    ? presentation[k].toString().toLowerCase().includes(term.toLowerCase())
                    : ""
            )
        );
        this.filtered = this.groupList(this.filtered, 'start_time');
      } else {
        this.filtered = [];
        this.resetList();
      }
      console.log(this.filtered)
    },

    groupList(list, selector) {
      let letters = [];
      let currentCharCode = '2010-01-01T00:00:00'; //$filter('date')(date, format, timezone)
      let sortedList = [];

      function addLetter(code) {
        let letter = code
        sortedList.push({
          isHeader: true,
          head: letter,
          start_time: letter
        });
        letters.push(letter);
      }

      (list || []).forEach(function (item) {
        if (item[selector]) {
          let itemCharCode = item[selector];
          let difference = new Date(itemCharCode) - new Date(currentCharCode);
          if (difference > 0)
            addLetter(itemCharCode);
          currentCharCode = itemCharCode;
          sortedList.push(item);
        }
      });
      return sortedList;
    },
  },
   watch: {
    '$route': {
      // with immediate handler gets called on first mount aswell
      immediate: true,
      // handler will be called every time the route changes.
      // reset your local component state and fetch the new data you need here.
      async handler(to) {
        if (to.name == 'Poster List') {
          this.presentations= await this.getAllPosters()
          console.log(this.presentations)
          this.moment = moment;

        }

      }
    }
  }

};
</script>
<style scoped>
a {
  text-decoration: none;
}
</style>